import styled from 'styled-components'

import { LazyImage } from 'components/atoms/Image'

import media from 'styles/media'

import line from 'assets/icons/line.svg'

export const OverlayedPhotoWrapper = styled.section`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.4) 1%,
      transparent
    );
    z-index: 2;
  }

  ${media.lg.min} {
    &:after {
      content: '';
      width: 855px;
      height: 75px;

      position: absolute;
      right: -480px;
      bottom: 120px;
      transform: translateY(100%);
      z-index: 99999999;

      background-image: url(${line});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`

export const OverlayedPhotoInner = styled.div`
  position: relative;
  padding: 128px 0;
  width: 100%;
  max-width: 450px;
  z-index: 3;

  ${media.lg.max} {
    padding: 96px 0;
    max-width: 700px;
  }
`
export const OverlayedPhotoBackground = styled(LazyImage)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &::before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &:after {
    background-image: linear-gradient(75deg, #000, transparent);
    z-index: 2;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.lg.min} {
    &:before {
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }

  ${media.lg.max} {
    position: relative;
    left: -32px;
    margin: 48px 0;
    width: calc(100vw + 16px);
    height: 75vh;
    max-height: 700px;
    &:after {
      background: linear-gradient(
        to bottom,
        #000 0%,
        rgba(0, 0, 0, 0.1) 30%,
        rgba(0, 0, 0, 0.1) 70%,
        #000 100%
      );
    }

    &:before {
      content: '';
      width: 355px;
      height: 48px;
      position: absolute;
      right: -170px !important;
      bottom: 120px !important;
      left: auto;
      top: auto;
      transform: translateY(100%);
      z-index: 99999999;
      background-image: url(${line});
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`

export const OverlayedPhotoArticle = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 96px 0;
  z-index: 3;
  &:last-child {
    margin: 0;
  }

  ${media.lg.max} {
    margin: 0;
  }
`

export const OverlayedPhotoTitle = styled.h3`
  position: relative;
  margin: 0 0 16px 0;
  padding: 0;
  font-size: 44px;
  line-height: 52px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    font-size: 30px;
    line-height: 38px;
  }
`

export const OverlayedPhotoText = styled.div`
  position: relative;

  p {
    margin: 0 0 32px 0;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};

    strong {
      font-weight: 700;
    }
    &:last-child {
      margin: 0;
    }

    ${media.lg.max} {
      font-size: 18px;
      line-height: 26px;
    }
  }
`
