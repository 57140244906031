import React, { useState, useEffect } from 'react'
import { PageProps, graphql } from 'gatsby'
import styled from 'styled-components'
import slugify from 'slugify'
import { v4 as uuidv4 } from 'uuid'

import LayoutBasic from 'layouts/LayoutBasic'
import Seo from 'components/atoms/Seo'
import BannerMain from 'components/organisms/Banner/BannerMain'
import GalleryGrid from 'components/organisms/Gallery/GalleryGrid'
import Offer from 'components/organisms/Offer'
import ContactModal from 'components/organisms/ContactModal'
import OverlayedPhoto from 'components/organisms/OverlayedPhoto/OverlayedPhoto'
import OverlayedGallery from 'components/organisms/OverlayedPhoto/OverlayedGallery'
import FindDealer from 'components/organisms/FindDealer'

import type { DealerPoint } from 'types/domain'
import media from 'styles/media'

const PageFindDealer = styled.div`
  margin: 128px 0;

  ${media.lg.max} {
    margin: 96px 0 0 0;
  }
`

const PageIndex: React.FC<PageProps<Queries.PageIndexQuery>> = ({ data }) => {
  const PAGE = data?.PageHome?.PageHome!
  const DEALERS = data?.PageDealers?.PageDealers!
  const SEO = data?.PageHome?.seo!

  if (!PAGE || !DEALERS || !SEO)
    throw new Error("CMS data didn't load properly")

  const [visibleModalContact, setVisibleModalContact] = useState(false)

  const dataGalleryGridList = PAGE?.galleryList!.map((item) => ({
    text: item?.galleryListText!,
    image: item?.galleryListImg?.localFile?.childImageSharp?.gatsbyImageData!,
  }))

  const dataOfferProducts = PAGE?.offerList!.map((product) => ({
    title: product?.offerListTitle!,
    slug: slugify(product?.offerListTitle!, { lower: true }),
    text: product?.offerListText!,
    gallery:
      product?.offerListGallery?.map((item) => ({
        image: item?.localFile?.childImageSharp?.gatsbyImageData!,
      })) || [],
    price: product?.offerListPrice!,
    specification: {
      tabs:
        product?.offerListSpecification?.map((tab) => ({
          title: tab?.offerListSpecificationTitle!,
          list:
            tab?.offerListSpecificationTable!.map((property) => ({
              label: property?.offerListSpecificationTableTitle!,
              value: property?.offerListSpecificationTableText!,
            })) || [],
        })) || [],
    },
  }))

  const dataOverlayedCarousel = PAGE?.overlayedList?.map((slide) => ({
    title: slide?.overlayedListTitle!,
    background:
      slide?.overlayedListImg?.localFile?.childImageSharp?.gatsbyImageData!,
  }))

  const dataOverlayedArticles = PAGE?.descriptionContent!.map((article) => ({
    title: article?.descriptionContentTitle!,
    text: article?.descriptionContentText!,
  }))

  const DEALERS_POINTS: DealerPoint[] =
    DEALERS?.dealersList
      ?.filter(
        (dealer) => dealer?.dealersListName && dealer?.dealersListAddress
      )
      ?.map((dealer) => ({
        name: dealer?.dealersListName!,
        address: dealer?.dealersListAddress!,
        openingHours: dealer?.dealersListHoursopen!,
        phoneNumber: dealer?.dealersListPhone!,
        routeLink: dealer?.dealersListGooglemap!,
        latitude: Number(dealer?.dealersListAddressLat)!,
        longitude: Number(dealer?.dealersListAddressLng)!,
        isShowroom: Boolean(dealer?.dealersListShowroom),
        isServicePoint: Boolean(dealer?.dealersListService),
        id: uuidv4(),
        postCode: dealer?.dealersListZipcode!,
        city: dealer?.dealersListCity!,
      })) || []

  useEffect(() => {
    if (visibleModalContact) document.documentElement.style.overflow = 'hidden'
    else document.documentElement.style.overflow = 'auto'
  }, [visibleModalContact])

  return (
    <LayoutBasic>
      <Seo
        title={SEO?.title!}
        description={SEO?.metaDesc!}
        ogTitle={SEO?.opengraphTitle!}
        ogDescription={SEO?.opengraphDescription!}
        ogImage={SEO?.opengraphImage?.sourceUrl!}
        twitterTitle={SEO?.twitterTitle}
        twitterDescription={SEO?.twitterDescription}
        twitterImage={SEO?.twitterImage?.sourceUrl}
      />
      <BannerMain
        title={PAGE?.bannerTitle!}
        video={PAGE?.bannerVideo?.localFile?.publicURL!}
      />
      <GalleryGrid text={PAGE?.galleryText!} items={dataGalleryGridList} />
      <Offer
        products={dataOfferProducts}
        displayModalContact={() => setVisibleModalContact(true)}
      />
      <OverlayedGallery id="lokalizacja" slides={dataOverlayedCarousel!} />
      <OverlayedPhoto
        articles={dataOverlayedArticles}
        background={
          PAGE?.descriptionBg?.localFile?.childImageSharp?.gatsbyImageData!
        }
      />
      <PageFindDealer>
        <FindDealer points={DEALERS_POINTS} page="homepage" />
      </PageFindDealer>
      <ContactModal
        id="test-drive"
        active={visibleModalContact}
        onClose={() => setVisibleModalContact(false)}
      />
    </LayoutBasic>
  )
}

export default PageIndex

export const query = graphql`
  query PageIndex {
    PageHome: wpPage(slug: { regex: "/strona-glowna/" }) {
      PageHome {
        bannerTitle
        bannerVideo {
          localFile {
            publicURL
          }
        }
        galleryText
        galleryList {
          galleryListImg {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000)
              }
            }
          }
          galleryListText
        }
        offerList {
          offerListTitle
          offerListText
          offerListPrice
          offerListGallery {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000)
              }
            }
          }
          offerListSpecification {
            offerListSpecificationTitle
            offerListSpecificationTable {
              offerListSpecificationTableTitle
              offerListSpecificationTableText
            }
          }
        }
        descriptionContent {
          descriptionContentText
          descriptionContentTitle
        }
        descriptionBg {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
          }
        }
        overlayedList {
          overlayedListImg {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000)
              }
            }
          }
          overlayedListTitle
        }
      }
      seo {
        title
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
    }
    PageDealers: wpPage(slug: { regex: "/dealerzy/" }) {
      PageDealers {
        dealersList {
          dealersListName
          dealersListZipcode
          dealersListShowroom
          dealersListServiceMobile
          dealersListService
          dealersListPhone
          dealersListName
          dealersListHoursopen
          dealersListGooglemap
          dealersListCity
          dealersListAddressLng
          dealersListAddressLat
          dealersListAddress
        }
      }
    }
  }
`
