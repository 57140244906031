import styled from 'styled-components'
import { Accordion, AccordionItem } from '@szhsin/react-accordion'

import media from 'styles/media'

import angleDown from 'assets/icons/angle-down.svg'

export const AccordionWrapper = styled(Accordion)``

export const AccordionTab = styled(AccordionItem)`
  position: relative;
  margin: 0 0 18px 0;
  &:last-child {
    margin: 0;
  }
  ${media.lg.max} {
    margin: 0 0 8px 0;
  }

  .szh-accordion__item {
    &-btn {
      position: relative;
      padding: 24px 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      border: 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.black};
      cursor: pointer;
      transition: background-color 300ms ease-in-out;
      &:before {
        content: '';
        position: absolute;
        right: 24px;
        top: 50%;
        width: 32px;
        height: 32px;
        background-image: url(${angleDown});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
        transform: translateY(-50%) rotate(-180deg);
        filter: brightness(0) invert(1);
        transition: transform 300ms ease-in-out;

        ${media.lg.max} {
          right: 16px;
          background-size: 16px;
        }
      }
      ${media.lg.max} {
        padding: 16px 24px;
      }
    }

    &-content {
      transition: height 0.2s ease-in-out;
    }

    &-panel {
      padding: 32px;
      background-color: ${({ theme }) => theme.colors.gray5};

      ${media.lg.max} {
        padding: 16px;
      }
    }
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      background-color: ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.black};
      &:before {
        filter: brightness(0) saturate(100%) invert(64%) sepia(68%)
          saturate(1201%) hue-rotate(15deg) brightness(105%) contrast(118%);
        transform: translateY(-50%) rotate(0);
      }
    }
  }
`

export const AccordionList = styled.ul`
  display: flex;
  flex-direction: column;
`

export const AccordionListItem = styled.li`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
  ${media.lg.max} {
    flex-direction: column;
  }
`

export const AccordionListLabel = styled.p`
  margin: 0;
  padding: 0 32px 0 0;
  width: 30%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  ${media.lg.max} {
    width: 100%;
  }
`

export const AccordionListValue = styled.div`
  width: 70%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  ${media.lg.max} {
    margin: 4px 0 0 0;
    width: 100%;
  }
`
