import React from 'react'

import {
  OverlayedPhotoWrapper,
  OverlayedPhotoBackground,
  OverlayedPhotoInner,
  OverlayedPhotoArticle,
  OverlayedPhotoTitle,
  OverlayedPhotoText,
} from 'components/organisms/OverlayedPhoto/OverlayedPhoto.style'

import useBreakpoint from 'hooks/useBreakpoint'

import { IGatsbyImageData } from 'gatsby-plugin-image'
import Container from 'components/atoms/Container'

type Props = {
  background: IGatsbyImageData
  articles: Array<{
    title?: string
    text?: string
  }>
}

const OverlayedPhoto: React.FC<Props> = ({ articles, background }) => {
  const { lg } = useBreakpoint()

  return (
    <OverlayedPhotoWrapper>
      {lg && <OverlayedPhotoBackground src={background} alt="" />}

      <Container>
        <OverlayedPhotoInner>
          {articles.map((article, articleIndex) => (
            <>
              <OverlayedPhotoArticle>
                {article.title && (
                  <OverlayedPhotoTitle
                    dangerouslySetInnerHTML={{
                      __html: article.title,
                    }}
                  />
                )}
                {article.text && (
                  <OverlayedPhotoText
                    dangerouslySetInnerHTML={{
                      __html: article.text,
                    }}
                  />
                )}
              </OverlayedPhotoArticle>
              {articleIndex === 0 && !lg && (
                <OverlayedPhotoBackground src={background} alt="" />
              )}
            </>
          ))}
        </OverlayedPhotoInner>
      </Container>
    </OverlayedPhotoWrapper>
  )
}

export default OverlayedPhoto
